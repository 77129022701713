import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import { DocumentField } from "src/app/model/document-field";
import { SmartqDocuments } from "src/app/model/smartq-documents.model";

export class AccidentalMedicalExpensesFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES];
    }

    setRequiredDocuments(){
        if (this.claim.isHKGorHGI()) {
            this.setRequiredDocumentsForHKG();
        } else {
            this.setRequirementDocumentsForSGP();
        }
    }

    setRequiredDocumentsForHKG() {
        let receiptlabel = 'paClaim.supportingDocs.types.copyMedReceipt';
        let hospitalDischarge = 'paClaim.supportingDocs.types.dischargeSlip';
        let copyReferral = 'paClaim.supportingDocs.types.copyReferralLetter';
        let medcert = 'paClaim.supportingDocs.types.medCert';

        // Initial setup of required documents
        let requiredDocuments: DocumentField[] = [];
        if (Array.isArray(this.requiredDocuments) && this.requiredDocuments.length > 0) {
            requiredDocuments = this.requiredDocuments;
        }

        if (this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted()) {
            if (!this.isDocExisting('RECEIPTS')) requiredDocuments.push(new DocumentField('RECEIPTS', receiptlabel, SmartqDocuments.PA_INVOICE, true));
            if (!this.isDocExisting('DISCHARGESLIP')) requiredDocuments.push(new DocumentField('DISCHARGESLIP', hospitalDischarge, SmartqDocuments.PA_MEDICALREPORT, true));
            if (!this.isDocExisting('COPYREFERRALLETTER')) requiredDocuments.push(new DocumentField('COPYREFERRALLETTER', copyReferral, SmartqDocuments.PA_MEDICAL, true));
            if (!this.isDocExisting('MEDCERT')) requiredDocuments.push(new DocumentField('MEDCERT', medcert, SmartqDocuments.PA_OTHER, true));
            this.removeDocument('REFERRALLETTER');
        } else {
            if (!this.isDocExisting('RECEIPTS')) requiredDocuments.push(new DocumentField('RECEIPTS', receiptlabel, SmartqDocuments.PA_INVOICE, true));
            if (!this.isDocExisting('MEDCERT')) requiredDocuments.push(new DocumentField('MEDCERT', medcert, SmartqDocuments.PA_OTHER, true));
            this.setAdditionalDocumentsForClinicalExpense();
            this.removeDocument('DISCHARGESLIP');
            this.removeDocument('COPYREFERRALLETTER');
        }        

        this.requiredDocuments = requiredDocuments;
    }

    setRequirementDocumentsForSGP() {
        let requiredDocuments: DocumentField[] = [];

        requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('RECEIPTS', 'paClaim.supportingDocs.types.copyMedInvoices', SmartqDocuments.PA_INVOICE, true), true));
        if (this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted()) {
            requiredDocuments.push(new DocumentField('DISCHARGESLIP', 'paClaim.supportingDocs.types.hospDischargeSlip', SmartqDocuments.PA_MEDICALREPORT, true));
        }
        requiredDocuments.push(new DocumentField('DOCMEMO', 'paClaim.supportingDocs.types.doctorsMemo', SmartqDocuments.PA_MEDICAL, true))
        requiredDocuments.push(new DocumentField('MEDCERT', 'paClaim.supportingDocs.types.medCertPayslip', SmartqDocuments.PA_MEDICALREPORT, true));
        requiredDocuments.push(new DocumentField('MEDISAVE', 'paClaim.supportingDocs.types.medisaveStatement', SmartqDocuments.PA_REPORT, true));
        if (this.claim.getOtherPolicyQuestion() === 'Y') {
            requiredDocuments.push(new DocumentField('SETTLEMENTLETTER', 'paClaim.supportingDocs.types.settlementLetter', SmartqDocuments.PA_OTHER, true));
        }
        requiredDocuments.push(new DocumentField('INVESTIGATIONRESULT', 'paClaim.supportingDocs.types.investigationResult', SmartqDocuments.PA_MEDICALREPORT, true));
        requiredDocuments.push(new DocumentField('MEDREPORT', 'paClaim.supportingDocs.types.medReportExtentInjury', SmartqDocuments.PA_MEDICALREPORT, true));
        requiredDocuments.push(new DocumentField('POLICEREPORT', 'paClaim.supportingDocs.types.policeReport', SmartqDocuments.PA_OTHER, true));

        this.requiredDocuments = requiredDocuments;
    }


    setAdditionalDocumentsForClinicalExpense() {
        let referral = 'paClaim.supportingDocs.types.referralLetter';
        if (this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getClinicalExpenses()?.find(
            clinicalExpense => ['Specialist', 'Diagnostic Testing'].indexOf(clinicalExpense.getMedicalAttention()) !== -1)) {
                if (!this.isDocExisting('REFERRALLETTER'))  this.requiredDocuments?.push(new DocumentField('REFERRALLETTER', referral, SmartqDocuments.PA_MEDICAL, true));
        } else {
            this.removeDocument('REFERRALLETTER');
        }
    }

    isDocExisting(docId: string): boolean {
        let exists = false;
        if (Array.isArray(this.requiredDocuments) && this.requiredDocuments.length > 0) {
            exists =  this.requiredDocuments?.findIndex(doc => doc.getId() === docId) !== -1;
        }
        return exists;
    }

    removeDocument(docId: string) {
        const index = this.requiredDocuments?.findIndex(document => document.getId() === docId);
        if (index && index !== -1) {
            this.requiredDocuments?.splice(index, 1);
        }
    }
}
import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { DocumentFormFactoryService } from "src/app/services/document-form-factory.service";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { ClaimTypes } from "src/app/model/claim-type.model";
import { ClaimDocumentsComponent } from "src/app/claim-pages/claim-documents/claim-documents.component";
import { ActivatedRoute, Router } from "@angular/router";
import {UtilitiesService} from '../../../../../../utilities/utilities.service';
import {TranslateService} from '@ngx-translate/core';
import {Claim} from "../../../../../../model/claim.model";
import {DocumentField} from "../../../../../../model/document-field";

@Component({
    selector: 'app-accidental-medical-expenses-upload-document',
    templateUrl: './accidental-medical-expenses-upload-document.component.html',
    styleUrls: ['./accidental-medical-expenses-upload-document.component.css']
})
export class AccidentalMedicalExpensesUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    upLoadFileModel: FormGroup;
    showTotalBox = false;
    wasAdmitted = false;
    claim: Claim;
    isSGP = false;
    requiredDocuments: DocumentField[];

    constructor(private fb: FormBuilder,
                private sideMenuService: SideMenuService,
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal,
                private translate: TranslateService) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]);
                this.claim = this.claimService.getClaim();
                this.wasAdmitted = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted();
                this.isSGP = this.claim.getCountry()?.toUpperCase() === 'SGP';
    }

    ngOnInit() {
        this.pushGAView();
        super.ngOnInit();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                otherDoc: [],
                otherItems: this.fb.array([this.createItem()])

            });
        this.requiredDocuments = this.formBuilder.getRequiredDocuments();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goBack() {
        let returnUrl = this.wasAdmitted ? '/claimform/accidentalMedicalExpenses/outpatient' :
            '/claimform/accidentalMedicalExpenses/hospital';
        let url = this.isSGP ? "/claimform/anotherInsurance"
                    : returnUrl;
        this.router.navigate([url], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        let stpDocIdList = this.requiredDocuments
            .reduce((result, { id, stpRequired }) => stpRequired == true ? result.push(id) && result : result, []);
        let stpDocAlertMessage = this.buildSTPAlertMessage(this.claim, this.translate, stpDocIdList);

        if(!UtilitiesService.isEmpty(stpDocAlertMessage)){
            this.showDocAlertModal();
        } else {
            this.redirectToNext();
        }
    }

    redirectToNext() {
        let url = "/claimform/anotherInsurance";
        let subIndex = 3;
        if (this.isSGP) {
            url = "/claimform/paymentAndContact";
            subIndex = 5;
        }
        
        this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: subIndex});
        this.sideMenuService.emitProgress(1);
        this.sideMenuService.emitComplete('finishChooseClaim');
        this.router.navigate([url], {
            relativeTo: this.activatedRoute
        });
    }

    showDocAlertModal() {
        this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    continueToNext(){
        this.redirectToNext();
        this.docAlertModalRef.close();
    }

    //Google Analytics
    pushGAView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Document Upload',
            'vPath': '/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 7},	//Step6-DocumentUpload
                }
            }
        });
    }
}

<div class="form-group">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
    <div class="col-md-{{uiModel.size}}">
        <div class="btn-group btn-group-justified" role="group" aria-label="...">
            <div class="btn-group" role="group">
            <button type="button" class="btn btn-default btn-lg active">Tenant</button>
            </div>
            <div class="btn-group" role="group">
            <button type="button" class="btn btn-default btn-lg">Owner</button>
            </div>
        </div>         
    </div>
    <div class="col-md-1">
    <span class="glyphicon glyphicon-info-sign pull-right info-icon-popover" data-original-title=""
            title="Revenue" data-bs-toggle="popover" data-trigger="hover" data-content="Revenue content"></span>
    </div>
</div>
